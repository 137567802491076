import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

//--------------Import Services -----------------//
import { create } from "../../../services/Api.service";

export default function View() {
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    code: "",
    discount: "",
    image: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validateFormFields(formData);

    if (Object.keys(errors).length == 0) {
      try {
        const res = await create(formData, `admin/coupons`);

        if (res.status == 200) {
          setValidationErrors({
            success: "Changes Saved Successfully!! Redirecting...",
          });
          setTimeout(() => {
            navigate("/coupons");
          }, 2000);
        } else {
          setValidationErrors({
            err: "Some Error Occurred. Please try again later !!",
          });
        }
      } catch (error) {
        console.log(error);
        setValidationErrors({
          err: "An error occurred. Please try again later.",
        });
      }
      setFormData({});
      // setValidationErrors({});
    } else {
      setValidationErrors(errors);
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};

    if (!formData.title || formData.title.trim() === "") {
      errors.title = "Please enter title";
    }

    if (!formData.description || formData.description.trim() === "") {
      errors.description = "Please enter description";
    }

    if (!formData.code || formData.code === "") {
      errors.price = "Please enter price";
    }

    if (!formData.discount || formData.discount === "") {
      errors.count = "Please enter count";
    }

    return errors;
  };

  return (
    <>
      <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div className="overview-header">
          <Link type="submit" className="adminbtn backbtn" to="/coupons">
            <i className="fa fa-reply"></i> Back
          </Link>
          <h1 className="h2">Add Coupon</h1>
        </div>
        <div className="col-sm-6">
          <div className="bigform">
            {validationErrors.success && (
              <span className="success">{validationErrors.success}</span>
            )}
            {validationErrors.err && (
              <div className="login-error">{validationErrors.err}</div>
            )}

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-6">
                  <label>
                    Title:<span className="error">*</span>
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="form-control mb-2"
                    placeholder="Please enter title..."
                    value={formData?.title}
                    onChange={handleInputChange}
                  />

                  {validationErrors.title && (
                    <div className="error login-error">
                      {validationErrors.title}
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <label>
                    Description<span className="error">*</span>
                  </label>
                  <textarea
                    name="description"
                    className="form-control mb-2"
                    placeholder="Please enter description..."
                    value={formData?.description}
                    onChange={handleInputChange}
                  ></textarea>

                  {validationErrors.description && (
                    <div className="error login-error">
                      {validationErrors.description}
                    </div>
                  )}
                </div>

                <div className="col-6">
                  <label>
                    Code<span className="error">*</span>
                  </label>
                  <input
                    type="text"
                    name="code"
                    className="form-control mb-2"
                    value={formData?.code}
                    onChange={handleInputChange}
                  />
                  {validationErrors.code && (
                    <div className="error login-error">
                      {validationErrors.code}
                    </div>
                  )}
                </div>

                <div className="col-6">
                  <label>
                    Discount(%)<span className="error">*</span>
                  </label>
                  <input
                    type="number"
                    name="discount"
                    className="form-control mb-2"
                    value={formData?.discount}
                    onChange={handleInputChange}
                  />
                  {validationErrors.discount && (
                    <div className="error login-error">
                      {validationErrors.discount}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Button type="submit" className="adminbtn">
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
