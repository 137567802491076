import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

//--------------Import Services -----------------//
import { create, remove } from "../../../services/Api.service";
import { timeAgo } from "../../../services/Helper.service";

import DeleteConfirmation from "../../Globals/Confirm";

export default function UsersManage() {
  const [data, setData] = useState([]);
  const [successMsg, setSuccessMsg] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  useEffect(() => {
    create([], "admin/users/all").then((response) => {
      if (response.status == 200) {
        setData(response.data.data);
      }
    });
  }, []);

  const handleDelete = (id) => {
    remove(`user/delete/${id}`).then((response) => {
      if (response.status == 200) {
        create([], "admin/users/all").then((response) => {
          if (response.status == 200) {
            setData(response.data.data);
          }
        });

        setDisplayConfirmationModal(false);
        setSuccessMsg("Deleted Successfully!!!");
      }
    });
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // Handle the displaying of the modal based on type and id
  const showDeleteModal = (id) => {
    setDeleteMessage(`Are you sure you want to delete?`);
    setDeleteId(id);
    setDisplayConfirmationModal(true);
  };

  return (
    <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
      <div className="overview-header">
        {successMsg && <Alert variant="success">{successMsg}</Alert>}

        <h1 className="h2">Manage Users</h1>

        {/* <div className="buttons-right">
          <Button className="me-2" variant="primary" onClick={() => setShowModal(true)}>
            Add User
          </Button>
          <Link>
            <Button variant="light">
              <i className="fa-solid fa-file-import"></i> Import User
            </Button>
          </Link>
        </div> */}
      </div>

      {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add New User</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* <AddUserForm onAdd={handleAdd} /> */}
      {/* </Modal.Body> */}

      {/* </Modal> */}

      <table className="table">
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Active</th>
            <th>Registered On</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <>
                <td>{++index}</td>
                <td>
                  {item.fname} {item.lname}
                </td>
                <td>{item.email}</td>
                <td>{item.contact}</td>
                <td>{item.active == 1 ? "Yes" : "No"}</td>
                <td>{timeAgo(item.created_on)}</td>
                <td className="delete-view-btn">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to={`/users/view/${btoa(item.id)}`}
                  >
                    <i className="fa-regular fa-eye"></i>
                  </Link>
                  <Link
                    variant="danger"
                    onClick={() => showDeleteModal(item.id)}
                  >
                    <i className="fa-solid fa-trash-can"></i>
                  </Link>
                </td>
              </>
            </tr>
          ))}
        </tbody>
      </table>

      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={handleDelete}
        hideModal={hideConfirmationModal}
        deleteId={deleteId}
        message={deleteMessage}
      />
    </div>
  );
}
