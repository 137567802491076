import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { postApiWithoutToken } from '../../services/Api.service';

const ConfirmAccount = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [confirmed, setConfirmed] = useState(false);
    let token = params.token;

    // On componentDidMount set the timer
    useEffect(() => {
        postApiWithoutToken({ token }, 'user/confirm-account').then((res) => {
            if (res.status == 200) {
                setConfirmed(true)
            }
        });

        const timeId = setTimeout(() => {
            navigate("/login")
        }, 10000)

        return () => {
            clearTimeout(timeId)
        }
    }, []);

    return (
        <>
            <span> Redirecting....</span>
            {confirmed ?
                (<span>Your account was confirmed successfully. Please login to continue!!.</span>)
                : (<span>Some error occured while confirming your account. Please check back later!!</span>)}
        </>
    )
}

export default ConfirmAccount;