import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TopNavbar from "../User/TopNav";
import { ListGroup, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
export default function Dashboard() {
  let navigate = useNavigate();
  return (
    <>
      <div className="container">
        <TopNavbar />
        <button
          className="btn backbtn backbtnuser"
          onClick={() => navigate(-1)}
        >
          {" "}
          <i class="fa-solid fa-chevron-left"></i> Back
        </button>
        <div class="row justify-content-center">
          <main className="col-sm-4 align-self-center">
            <div className="overview-header">
              <h1 className="h2">Profile</h1>
            </div>

            <Tabs defaultActiveKey="editprofile" className="mb-3 profile-tabs">
              <Tab eventKey="profile" title="Profile">
                <ListGroup>
                  <ListGroup.Item>
                    <b>Name</b> Dheeaj V
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Contact</b> 9876543210
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <b>Address</b> Malad, NH47
                  </ListGroup.Item>
                </ListGroup>
              </Tab>
              <Tab eventKey="editprofile" title="Edit Profile">
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Edit Name</Form.Label>
                    <Form.Control type="text" placeholder="Dheeraj" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Edit Contact</Form.Label>
                    <Form.Control type="text" placeholder="978709676" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Edit Address</Form.Label>
                    <Form.Control type="text" placeholder="malad, mh47" />
                  </Form.Group>
                  <div className="d-flex justify-content-end">
                    <button className="savebtn">Save</button>
                  </div>
                </Form>
              </Tab>
              <Tab eventKey="editpassword" title="Edit Passowrd">
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control type="password" placeholder="old password" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control type="password" placeholder="new password" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="confirm new password"
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-end">
                    <button className="savebtn">Save</button>
                  </div>
                </Form>
              </Tab>
            </Tabs>
          </main>
        </div>
      </div>
    </>
  );
}
