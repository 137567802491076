import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Thanks = () => {

    const navigate = useNavigate();

    // On componentDidMount set the timer
    useEffect(() => {
        const timeId = setTimeout(() => {
            navigate("/login")
        }, 10000)

        return () => {
            clearTimeout(timeId)
        }
    }, []);

    return (
        <>
            <span> Redirecting....</span>
            <span> Thank you for the payment. We're verifying your payment. Till the time you can login.</span>
        </>
    )
}

export default Thanks;