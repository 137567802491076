import React from 'react';
import {
    Route,
    Routes,
} from 'react-router-dom';

import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
import Dashboard from "./Dashboard";
import EditProfile from "./EditProfile";
import Scan from "./Scan";
import Checkout from "./Checkout";
import ConfirmAccount from './ConfirmAccount';

export default function UserRoutes() {
    return (
        <Routes>
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/edit-profile/:id" element={<EditProfile />} />
            <Route path="/scan" element={<Scan />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/confirm-account/:token" element={<ConfirmAccount />} />
        </Routes>
    );
}