import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ModalProduct from "../User/Modal";
import TopNavbar from "../User/TopNav";
import { fetchApi } from '../../services/Api.service';

export default function Dashboard() {
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [tags, setTags] = useState("");
  let userdetails = JSON.parse(localStorage.getItem("userdetails"));

  const handleCloseProductDetails = () => setShowProductDetails(false);
  const handleShowProductDetails = () => setShowProductDetails(true);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {

    if (userdetails == null) {
      localStorage.removeItem("userdetails");
      localStorage.removeItem("token")
      navigate("/login");
    }

    fetchApi(`user/barcodes/all/${userdetails.id}/0`).then((response) => {
      if (response && response.status == 200) {
        setTags(response.data.data);
      } else {
        setValidationErrors({
          error: "Some error occurred. Please try later!!!",
        });
      }
    });
  })


  return (
    <>
      <div className="container">
        <TopNavbar />
        <main className="col-sm-12">
          <div className="overview-header">
            <h1 className="h2">Dashboard</h1>
          </div>

          <div className="overview-table">
            <h2 className="overview-header">Latest QR Created</h2>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Address</th>
                  <th scope="col">Contact</th>
                  <th scope="col">Product</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>rajnesh</th>
                  <td>mumbai</td>
                  <td>978965998</td>
                  <td>mobile</td>
                  <td className="tableaction">
                    <Link to="#" onClick={handleShowProductDetails}>
                      <i className="fa-regular fa-eye"></i>
                    </Link>
                    <Link>
                      <i className="fa-regular fa-trash-can"></i>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>raj</th>
                  <td>delhi</td>
                  <td>787654458</td>
                  <td>Bag</td>
                  <td className="tableaction">
                    <Link onClick={handleShowProductDetails}>
                      <i className="fa-regular fa-eye"></i>
                    </Link>
                    <Link>
                      <i className="fa-regular fa-trash-can"></i>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th>rajan</th>
                  <td>lucknow</td>
                  <td>Thordfdnton</td>
                  <td>wallet</td>
                  <td className="tableaction">
                    <Link onClick={handleShowProductDetails}>
                      <i className="fa-regular fa-eye"></i>
                    </Link>
                    <Link>
                      <i className="fa-regular fa-trash-can"></i>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>

      <ModalProduct
        show={showProductDetails}
        handleClose={handleCloseProductDetails}
      />
    </>
  );
}
