import React, { useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { postApiWithoutToken } from "../../services/Api.service";

export default function ResetPassword() {
  const params = useParams();
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [showPass, setShowPass] = useState(false)
  const [showConfPass, setConfShowPass] = useState(false)

  const [formData, setFormData] = useState({
    newPass: "",
    confirmPass: "",
    token: params.token
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const HandleResetPass = async (e) => {
    e.preventDefault();
    let errors = validateFormFields(formData);

    if (Object.keys(errors).length == 0) {
      try {
        const res = await postApiWithoutToken(formData, `user/reset-password`);
        
        if (res.status == 200) {
          let flash = { type: "success", msg: "Your Password Was Changed Successfully. Please Login to Continue!!" }
          navigate("/login", { state: { flash } })
        } else {
          setValidationErrors({
            err: "Some Error Occurred. Please try again later !!",
          });
        }
      } catch (error) {
        setValidationErrors({
          err: "An error occurred. Please try again later.",
        });
      }
    } else {
      setValidationErrors(errors)
      // setFormData({});
      // setValidationErrors({});Errors(errors);
    }
  };

  const validateFormFields = (formData) => {
    const errors = {};

    if (!formData.newPass || formData.newPass.trim() === '') {
      errors.newPass = 'Please enter a new password';
    }

    if (!formData.confirmPass || formData.confirmPass.trim() === '') {
      errors.confirmPass = 'Please confirm your password';
    }

    if (formData.newPass !== formData.confirmPass) {
      errors.err = 'Password and Confirm Passwords should be same';
    }

    return errors;
  };

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col sm="5">
            {validationErrors.err && (
              <div
                className="alert alert-danger"
                role="alert"
              >
                {validationErrors.err}
              </div>
            )}
            {validationErrors.success && (
              <div
                className="alert alert-success offer-success-message"
                role="alert"
              >
                {validationErrors.success}
              </div>
            )}
            <div className="loginpage">
              <h1>Reset Password</h1>
              <div className="loginchild">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Group className="mb-3" >
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type={showPass ? "text" : "password"}
                        name="newPass"
                        value={formData.newPass || ''}
                        placeholder="Please enter password.."
                        onChange={handleInputChange}
                        required />
                      <span toggle="#password-field" className={`fa fa-fw ${showPass ? "fa-eye" : "fa-eye-slash"} field-icon toggle-password`} onClick={() => setShowPass(!showPass)}></span>
                      {validationErrors.newPass && <div className="error login-error">{validationErrors.newPass}</div>}
                    </Form.Group>
                    <Form.Group className="mb-3" >
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type={showConfPass ? "text" : "password"}
                        name="confirmPass"
                        value={formData.confirmPass || ''}
                        placeholder="Please confirm your password.."
                        onChange={handleInputChange}
                        required />
                    </Form.Group>
                    <span toggle="#password-field" className={`fa fa-fw ${showConfPass ? "fa-eye" : "fa-eye-slash"} field-icon toggle-password`} onClick={() => setConfShowPass(!showConfPass)}></span>
                    {validationErrors.confirmPass && <div className="error login-error">{validationErrors.confirmPass}</div>}
                  </Form.Group>

                  <button
                    type="submit"
                    onClick={HandleResetPass}
                    className="adminbtn"
                  >
                    Submit
                  </button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
