import { Route, Routes } from "react-router-dom";
import "./App.css";
import Homepage from "./components/landing/Homepage";
import Login from "./Login";
import "./components/Admin/style.css";
import Register from "./Register";
import DefaultContainer from "./components/Admin/DefaultContainer";
import Dashboard from "./components/Admin/Dashboard";
import Scan from "./components/User/Scan";

import ManageUsersRoutes from "./components/Admin/Users/UserRoutes";
import BarcodePacksRoutes from "./components/Admin/BarcodePacks/BarcodePacksRoutes";
import CouponsRoutes from "./components/Admin/Coupons/CouponsRoutes";
import UserRoutes from "./components/User/UserRoutes";
import Thanks from "./components/Globals/Thanks";

function App() {
  return (
    <Routes>
      <Route element={<DefaultContainer />}>
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/users/*" element={<ManageUsersRoutes />} />
        <Route path="/admin/barcode-packs/*" element={<BarcodePacksRoutes />} />
        <Route path="/admin/coupons/*" element={<CouponsRoutes />} />
      </Route>

      <Route path="/" element={<Homepage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register/*" element={<Register />} />
      <Route path="/register/*" element={<Register />} />

      <Route path="/users/*" element={<UserRoutes />} />
      <Route path="/lost/scan/qr/:tag" element={<Scan />} />
      <Route path="/thank-you" element={<Thanks />} />

    </Routes>
  );
}

export default App;
