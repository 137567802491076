import { NavLink } from "react-router-dom";

export default function SideMenu() {
  return (
    <>
      <nav
        id="sidebarMenu"
        className="col-md-3 col-lg-2 d-md-block sidebar collapse"
      >
        <div className="position-sticky pt-3 sidebar-sticky">
          <ul className="nav flex-column">
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/dashboard">
                <i className="fa-solid fa-border-all"></i> Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/users">
                <i className="fa-solid fa-users"></i> Users
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/barcode-packs">
                <i className="fa-solid fa-qrcode"></i> Barcode Packs
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" aria-current="page" to="/coupons">
                <i className="fa-solid fa-gift"></i> Coupons
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
