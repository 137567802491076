import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Modal from "react-bootstrap/Modal";

//--------------Import Services -----------------//
import { fetchApi } from "../../../services/Api.service";
import { timeAgo } from "../../../services/Helper.service";

export default function View() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const params = useParams();

  const [data, setData] = useState([]);
  const uid = atob(params.id);

  useEffect(() => {
    fetchApi(`user/profile/${uid}`).then((response) => {
      if (response.status === 200) {
        setData(response.data.user);
      }
    });
  }, []);

  return (
    <>
      <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div className="overview-header">
          <Link className="adminbtn backbtn" aria-current="page" to="/users">
            <i className="fa fa-reply"></i> Back
          </Link>
          <h1 className="h2">View User</h1>
        </div>
        <div className="col-sm-12">
          <div className="bigform">
            <form className="view-users">
              <div className="row">
                <div className="col-3">
                  <label>First name:</label>
                  <label>{data.fname}</label>
                </div>
                <div className="col-3">
                  <label>Last name: </label>
                  <label>{data.lname}</label>
                </div>
                <div className="col-3">
                  <label>Email: </label>
                  <label>{data.email}</label>
                </div>
                <div className="col-3">
                  <label>Contact: </label>
                  <label>{data.contact}</label>
                </div>
                <div className="col-3">
                  <label>Address: </label>
                  <label>{data.address}</label>
                </div>
                <div className="col-3">
                  <label>State: </label>
                  <label>{data.state}</label>
                </div>
                <div className="col-3">
                  <label>City: </label>
                  <label>{data.city}</label>
                </div>
                <div className="col-3">
                  <label>Zipcode: </label>
                  <label>{data.zipcode}</label>
                </div>
                <div className="col-3">
                  <label>Active: </label>
                  <label>{data.active == 1 ? "Yes" : "No"}</label>
                </div>
                <div className="col-3">
                  <label className="font-weight-bold">Registered On: </label>{" "}
                  <label>{timeAgo(data.created_on)}</label>
                </div>
              </div>
            </form>
          </div>
          <div className="userproductandbarcode">
            <div className="col-12">
              <Tabs
                id="controlled-tab-example"
                defaultActiveKey={1}
                className="mb-3"
              >
                <Tab eventKey={1} title="Products">
                  <div className="usersproductslist">
                    <ul>
                      <li>
                        <i className="fa fa-briefcase"></i> :<sapn>bag</sapn>
                      </li>
                      <li>
                        <i class="fa-solid fa-wallet"></i> :<sapn>Wallet</sapn>
                      </li>
                      <li>
                        <i class="fa-solid fa-mobile-screen"></i> :
                        <sapn>Mobile Phone</sapn>
                      </li>
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey={2} title="Barcodes">
                  <div className="usersproductslist usersbarcodelist">
                    <ul>
                      <li>
                        <Link onClick={handleShow}>
                          <i class="fa-solid fa-briefcase"></i> :
                          <sapn> bag </sapn>
                        </Link>
                      </li>
                      <li>
                        <Link onClick={handleShow}>
                          <i class="fa-solid fa-wallet"></i> :
                          <sapn> Wallet </sapn>
                        </Link>
                      </li>
                      <li>
                        <Link onClick={handleShow}>
                          <i class="fa-solid fa-mobile-screen"></i> :
                          <sapn> Mobile Phone </sapn>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Barcode Name</Modal.Title>
        </Modal.Header>
        <Modal.Body className="barcodeimg">
          <img src="https://dummyimage.com/250/000000/ffffff" />
          <button className="btn downloadbtn">Download</button>
        </Modal.Body>
      </Modal>
    </>
  );
}
