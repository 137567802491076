import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchApi,
} from "../../services/Api.service";
import { API_URL } from "../../services/Helper.service";
import creditcards from "../../img/creditcard-cropd.png";

export default function Checkout() {
  const [coupon, setCoupon] = useState("");
  const [userData, setUserData] = useState({});
  const [couponData, setCouponData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [PackData, setPackData] = useState([]);
  let userdetails = JSON.parse(localStorage.getItem("userdetails"));
  let token = localStorage.getItem("token");

  const navigate = useNavigate();
  const { state } = useLocation();
  let packId = ""

  if (state) {
    packId = atob(state.packId);
  }

  useEffect(() => {
    if (!packId) {
      navigate("/user/dashboard");
    }

    if (userdetails == null) {
      localStorage.removeItem("userdetails");
      localStorage.removeItem("token")
      navigate("/login");
    }

    fetchApi(`admin/barcode-packs?id=${packId}`).then((response) => {
      if (response && response.status == 200) {
        setPackData(response.data.data);
      } else {
        setValidationErrors({
          error: "Some error occurred. Please try later!!!",
        });
      }
    });

    fetchApi(`user/profile/${userdetails.id}`).then((response) => {
      if (response && response.status == 200) {
        setUserData(response.data.user);
      } else {
        setValidationErrors({
          error: "Some error occurred. Please try later!!!",
        });
      }
    });
  }, []);

  function handleApplyCoupon() {
    if (coupon == "") {
      setValidationErrors({ coupon: "Invalid Coupon Code!!!" });
      return false;
    }
    fetchApi(`user/search-coupon/${coupon}`).then((response) => {
      if (response && response.status == 200) {
        if ("coupon" in response.data) {
          let coupon = response.data.coupon;
          coupon.discountVal = (PackData.price * coupon.discount) / 100;
          coupon.discountPrice = PackData.price - coupon.discountVal;

          setCouponData(coupon);

          setValidationErrors({ success: "Coupon applied!!!" });
        } else {
          setValidationErrors({ coupon: "Invalid Coupon Code!!!" });
        }
      } else {
        setValidationErrors({
          error: "Some error occurred. Please try later!!!",
        });
      }
    });
  }
  return (
    <>
      <div className="container-fluid">
        <div className="checkoutform">
          <div className="row justify-content-center">
            <div className="col">
              <h2>Checkout Form</h2>
              {/* <span>Fill details below to proceed</span> */}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-8 order-last order-sm-first">
              <div className="container">
                <form
                  method="POST"
                  className="checkoutformbill"
                  action={`${API_URL}/user/start-payment`}
                >
                  <input
                    type="hidden"
                    id="token"
                    name="token"
                    value={"Basic " + token}
                  />
                  <input
                    type="hidden"
                    id="pack_id"
                    name="pack_id"
                    value={packId}
                  />
                  <input
                    type="hidden"
                    id="user_id"
                    name="user_id"
                    value={userdetails?.id}
                  />
                  <input
                    type="hidden"
                    id="coupon_id"
                    name="coupon_id"
                    value={couponData?.id}
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <h3>Billing Address</h3>
                      <div className="mb-3">
                        <label htmlFor="fullname" className="form-label">
                          <i className="fa fa-user"></i> Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="fname"
                          name="firstname"
                          disabled
                          value={userData.fname + " " + userData.lname}
                          aria-describedby="fullname"
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          <i className="fa fa-envelope"></i> Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          disabled
                          value={userData.email}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <h3>Payment</h3>
                      <label htmlFor="fname">Accepted Cards</label>
                      <img src={creditcards} />

                      <div className="input-group mb-3">
                        <span htmlFor="coupon" className="input-group-text">
                          COUPON
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          id="coupon"
                          name="coupon"
                          value={coupon}
                          onChange={(e) => setCoupon(e.target.value)}
                          aria-label="offer apply text-BOX"
                          aria-describedby="offer-applytext-box"
                        />
                        <button
                          className="btn btn-success"
                          type="button"
                          value="Apply"
                          onClick={handleApplyCoupon}
                          id="button-addon2"
                        >
                          Apply
                        </button>
                      </div>
                      {validationErrors.coupon && (
                        <div className="alert alert-danger" role="alert">
                          {validationErrors.coupon}
                        </div>
                      )}
                      {validationErrors.err && (
                        <span className="login-error offer-error-message">
                          {validationErrors.err}
                        </span>
                      )}
                      {validationErrors.success && (
                        <div
                          className="alert alert-success offer-success-message"
                          role="alert"
                        >
                          {validationErrors.success}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Continue to checkout"
                      className="btn"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-4 order-first order-sm-last">
              <div className="package-details">
                <div className="package-details-titile">
                  <h4>Cart</h4>
                  <span className="price" style={{ color: "black" }}>
                    <i className="fa fa-shopping-cart"></i> <b>1</b>
                  </span>
                </div>

                <div className="package-details-amt">
                  <span>{PackData.title}</span>{" "}
                  <span className="price">${PackData.price}</span>
                </div>
                <div className="package-details-amt">
                  <span>{PackData.description}</span>{" "}
                </div>
                <div className="package-details-amt">
                  <span className="bold">Total Tags:</span>{" "}
                  <span>{PackData.count}</span>{" "}
                </div>

                {couponData.discountVal && (
                  <div className="package-details-amt discount">
                    <span>Coupon discount({couponData.discount}%)</span>{" "}
                    <span className="price">-${couponData.discountVal}</span>
                  </div>
                )}
                <hr />
                <div className="package-details-amt">
                  <span>
                    <b>Total</b>
                  </span>
                  <span className="price" style={{ color: "black" }}>
                    <b>
                      $
                      {couponData.discountPrice
                        ? couponData.discountPrice
                        : PackData.price}
                    </b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
